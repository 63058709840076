import React, { useContext, useRef } from 'react';
import Link from 'src/front/components/Link';
import useLang from 'src/hooks/useLang';
import { IS_SERVER, DEFAULT_CLIENT_URI } from 'src/constants';
import Helper from 'src/front/helpers/Helper';
import { ArrowTop } from 'src/front/components/icons';
import $ from 'jquery';
import { FrontContext } from 'src/front/helpers/FrontContext';

const footerMenu = [
  { link: '/country', title: 'Weather forecast by countries' },
  { link: '/news/', title: 'News' },
  { link: '/widgets/', title: 'Weather widgets' },
  { link: '/advertising/', title: 'Advertising on the website' },
  { link: '/partnership/', title: 'Cooperation' },
  { link: '/service_information/', title: 'Service information' },
  { link: '/privacy-policy', title: 'Privacy policy' },
  { link: '/feedback/', title: 'Feedback' },
];

function Footer() {
  const { myCities } = useContext(FrontContext);
  const lang = useLang('Footer');

  const handleScrollToTop = (ev) => {
    ev.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 500);
  };

  return (
    <footer className="relative">
      <div id="footer" className="clear relative">
        <div className="rights-reserved">
          <div>
            <span className="text-16 color-2">{IS_SERVER ? "{print (staticData.language.localizations[localization].domain || config.defaultClientUri).replace(/^https?:\\/\\//, '')}" : (lang.currentLocalization.domain || DEFAULT_CLIENT_URI).replace(/^https?:\/\//, '')}</span>
            <span className="text-15 color-1"> © {lang.t('All rights reserved !year_from-!year_till', { '!year_from': 2012, '!year_till': (new Date()).getFullYear() })}</span>
          </div>
          <div>
            <span className="va-middle text-17 color-1">{lang.t('Service provider')}</span>
            <img width="55" height="12" loading="lazy" className="va-middle" alt="foreca" src={Helper.getFileUrl('images', 'foreka.png')}/>
          </div>
        </div>
        <ul className="menu">
          {
            footerMenu.map((menuItem) => {
              const link = menuItem.link === '/news/' ? (IS_SERVER ? "{print myCities[0]?.constructor === Object ? ('/news/' + (myCities[0].link.substring(0, 8) != '/israel/' ? myCities[0].link.split('/')[1] + '/' : '')) : ''}" : (myCities[0]?.constructor === Object ? `/news/${myCities[0].link.substring(0, 8) !== '/israel/' ? myCities[0].link.split('/')[1] + '/' : ''}` : '')) : menuItem.link;

              return (
                <li className="inline-block va-middle relative">
                  {
                    (IS_SERVER || lang.isLink(link)) &&
                    <>
                      {IS_SERVER && `{if (${lang.isLink(link)}) :}`}
                      <span className="text-15 color-1">{lang.t(menuItem.title)}</span>
                      {IS_SERVER && '{endif}'}
                    </>
                  }
                  {
                    (IS_SERVER || !lang.isLink(link)) &&
                    <>
                      {IS_SERVER && `{if (!(${lang.isLink(link)})) :}`}
                      <Link className="text-15 color-1" to={lang.l(link)}>{lang.t(menuItem.title)}</Link>
                      {IS_SERVER && '{endif}'}
                    </>
                  }
                </li>
              );
            })
          }
        </ul>
        <img alt="Icon" loading="lazy" src={Helper.getFileUrl('logotype', 'logo2.svg')}/>
        <ul className="social fs-0">
          {
            (IS_SERVER || lang.currentLocalization.instagramPage) &&
            <>
              {IS_SERVER && '{if (staticData.language.localizations[localization].instagramPage) :}'}
                <li className="inline-block va-middle instagram">
                  <a aria-label="Instagram" href={IS_SERVER ? '{print staticData.language.localizations[localization].instagramPage}' : lang.currentLocalization.instagramPage} target="_blank"></a>
                </li>
              {IS_SERVER && '{endif}'}
            </>
          }
          {
            (IS_SERVER || lang.currentLocalization.twitterPage) &&
            <>
              {IS_SERVER && '{if (staticData.language.localizations[localization].twitterPage) :}'}
                <li className="inline-block va-middle twitter">
                  <a aria-label="Twitter" href={IS_SERVER ? '{print staticData.language.localizations[localization].twitterPage}' : lang.currentLocalization.twitterPage} target="_blank"></a>
                </li>
              {IS_SERVER && '{endif}'}
            </>
          }
          {
            (IS_SERVER || lang.currentLocalization.facebookPage) &&
            <>
              {IS_SERVER && '{if (staticData.language.localizations[localization].facebookPage) :}'}
                <li className="inline-block va-middle facebook">
                  <a aria-label="Facebook" href={IS_SERVER ? "{print staticData.language.localizations[localization].facebookPage || ''}" : (lang.currentLocalization.facebookPage || '')} target="_blank"></a>
                </li>
              {IS_SERVER && '{endif}'}
            </>
          }
        </ul>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a id="scroll-to-top" className="ta-center fs-0" href="#" onClick={handleScrollToTop} aria-label="Scroll to top">
          <ArrowTop className="inline-block va-middle" color="#2E6CAB"/>
        </a>
      </div>
    </footer>
  );
}

export default Footer;