import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setUserData, logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import { session } from 'src/utils/cookies';

function Auth({ children }) {
  const { account: { user } } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logout())
      });

      // try {
      //   if (await session() || user === null) {
      //     const { user } = await authService.init();
      //     await dispatch(setUserData(user ? user : false));
      //   }
      // } catch (ex) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
