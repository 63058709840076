import React, { useContext } from 'react';
import loadable from '@loadable/component';
import LoadingScreen from 'src/components/LoadingScreen';
import RightSidebar from 'src/front/components/RightSidebar';
import ComponentStyle from 'src/components/ComponentStyle';
import Helper from 'src/front/helpers/Helper';
import useLang from 'src/hooks/useLang';
import Link from 'src/front/components/Link';
import { useHendleLogout } from 'src/front/components/AuthForms';
import { IS_SERVER } from 'src/constants';
import { useSelector } from 'react-redux';

const AccountManagementLayoutLoadable = loadable(() => import('src/front/views/AccountManagement/AccountManagementLayoutLoadable'));

const Fallback = (props) => <><RightSidebar {...props}/><main><LoadingScreen/></main><aside id="left-sidebar"></aside></>;

const AccountManagementLayout = ({ children, ...rest }) => {
  const { account: { user } } = useSelector((state) => state);
  const lang = useLang('AccountManagementLayout');
  const usedHandleLogout = useHendleLogout();
  let current = false;

  const handleLogout = (ev) => usedHandleLogout(ev);

  return (
    <AccountManagementLayoutLoadable {...rest} fallback={<Fallback {...rest}/>}>
      <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/account-management.css' }])} Preloader={<LoadingScreen/>}>
        {
          user &&
          <ul className="flex" id="account-management-menu">
            <li className={`relative${(current = rest.match.url === (IS_SERVER ? '/account-management' : lang.l('/account-management'))) ? ' current' : ''}`}>
              {
                current ?
                <span className="text-4 color-10">{lang.t('Profile')}</span> :
                <Link className="text-4 color-1" to={lang.l('/account-management')}>{lang.t('Profile')}</Link>
              }
            </li>
            <li className={`relative${(current = rest.match.url === (IS_SERVER ? '/account-management/notifications' : lang.l('/account-management/notifications'))) ? ' current' : ''}`}>
              {
                current ?
                <span className="text-4 color-10">{lang.t('Notifications')}</span> :
                <Link className="text-4 color-1" to={lang.l('/account-management/notifications')}>{lang.t('Notifications')}</Link>
              }
            </li>
            <li className={`relative${(current = rest.match.url === (IS_SERVER ? '/account-management/widgets' : lang.l('/account-management/widgets'))) ? ' current' : ''}`}>
              {
                current ?
                <span className="text-4 color-10">{lang.t('Widgets')}</span> :
                <Link className="text-4 color-1" to={lang.l('/account-management/widgets')}>{lang.t('Widgets')}</Link>
              }
            </li>
            <li>
              <button className="text-4 color-12" onClick={handleLogout}>{lang.t('Exit')}</button>
            </li>
          </ul>
        }
        {children}
      </ComponentStyle>
    </AccountManagementLayoutLoadable>
  );
};

export default AccountManagementLayout;