import React, { useContext } from 'react';
import { FrontContext } from 'src/front/helpers/FrontContext';
import Sticky from 'src/front/components/Sticky';
import RightSidebarContent from 'src/front/components/RightSidebarContent';

function RightSidebar({ stickyCallback, ...rest }) {
  const { rightSidebarBottomHook } = useContext(FrontContext);

  return (
    <aside id="right-sidebar">
      <Sticky name="right-sidebar" bottomHook={rightSidebarBottomHook ?? '#banner-160x600'} exportResizeCallback={stickyCallback} topFixedElement="header">
        <RightSidebarContent {...rest}/>
      </Sticky>
    </aside>
  );
}

export default RightSidebar;