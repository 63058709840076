import React, { useEffect, useState, useRef, useContext } from 'react';
import useLang from 'src/hooks/useLang';
import { useSnackbar } from 'notistack';
import $ from 'jquery';
import axios from 'axios';
import { API_URI, IS_SERVER } from 'src/constants';
import { useDispatch } from 'react-redux';
import { setUserData, logout, register } from 'src/actions/accountActions';
import { ArrowBack, Google, Facebook, Mail } from 'src/front/components/icons';
import Helper from 'src/front/helpers/Helper';
import { useHistory } from 'react-router';
import { useGoogleAuth, useFacebookAuth } from 'src/hooks/social';
import { FrontContext } from '../helpers/FrontContext';
import userService from 'src/services/userService';
import { session } from 'src/utils/cookies';

const errorFromArray = (error, lang) => Helper.toReact(Helper.parseHtmlString(Helper.getFieldValue(error, lang)));

let activeSocailData = null;

function AuthForms({ place, onBeforeAuth }) {
  const [formsState, setFormsState] = useState({
    form: '.personal-login-types',
    errors: {},
  });
  const lang = useLang('AuthForms');
  const isSubmitting = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const flag = useRef(true);
  const duration = 300;
  const googleAuth = useGoogleAuth();
  const facebookAuth = useFacebookAuth();
  const { staticData } = useContext(FrontContext);

  const handleToggleForms = (ev, c, n) => {
    ev.preventDefault();

    const w = $(ev.target).closest('.personal-forms');

    if (w.length) {
      flag.current = false;

      const _c = w.find(c);
      const _n = w.find(n);

      w.css({height: _c.height() + 'px'});
      _n.css({display: 'block', position: 'absolute', width: '100%', opacity: 0, left: 0, top: 0, padding: w.css('padding'), boxSizing: 'border-box'});

      w.animate({height: _n.height() + 'px'}, duration);
      _n.animate({opacity: 1}, duration);
      _c.animate({opacity: 0}, duration, () => {
        _c.removeAttr('style');
        _n.removeAttr('style');
        w.removeAttr('style');

        setFormsState({ ...formsState, form: n, errors: {}, messages: {} });

        flag.current = true;
      });
    }
  };

  const hendleLoginForm = async (ev) => {
    ev.persist();
    ev.preventDefault();
  
    if (!isSubmitting.current) {
      try {
        const errors = {};
        const email = $(ev.target).find('[name="email"]').val();
        const password = $(ev.target).find('[name="password"]').val();
        const googleCaptchaVersion = +($(ev.target).find('[name="googleCaptchaVersion"]').val() || 3);
        const googleCaptchaToken = googleCaptchaVersion === 3 ? await userService.getCaptchToken() : $(ev.target).find('[name="googleCaptchaV2Token"]').val();
    
        if (!email) errors.email = 'Email is required';
        if (!password) errors.password = 'Password is required';
        if (!googleCaptchaToken) {
          enqueueSnackbar(lang.t('Please pass verification'), {
            variant: 'error'
          });
        }
    
        if (Object.keys(errors).length) {
          setFormsState({ ...formsState, errors });
        } else if (googleCaptchaToken) {
          isSubmitting.current = true;
          setFormsState({ ...formsState, errors: {} });
          axios
            .post(`${API_URI}/user/login`, { email , password, googleCaptchaVersion, googleCaptchaToken }, { withCredentials: true })
            .then(async ({ data }) => {
              if ('errors' in data) {
                const { common, ...errors } = data.errors;

                if (Object.keys(errors).length) setFormsState({ ...formsState, errors });
                if (common) {
                  enqueueSnackbar(lang.t(common), {
                    variant: 'error'
                  });
                }

                if ('captchaV3Error' in data && googleCaptchaVersion === 3) {
                  $(ev.target).find('[name="googleCaptchaVersion"]').val(2);

                  userService.createCaptchaV2({
                    element: $(ev.target).find('.google-captcha-v2')[0],
                    callback: (t) => $(ev.target).find('[name="googleCaptchaV2Token"]').val(t),
                    expiredCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
                    errorCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
                  });
                } else if (googleCaptchaVersion === 2) {
                  $(ev.target).find('[name="googleCaptchaV2Token"]').val('');
                  userService.reloadCaptchaV2();
                }
              } else if ('user' in data) {
                const query = Helper.getQueryData();

                if (onBeforeAuth) onBeforeAuth();

                await dispatch(setUserData(data.user, true));

                if (query.redirect) history.push(lang.l(query.redirect));
              }
              isSubmitting.current = false;
            });
        }
      } catch (ex) {
        enqueueSnackbar(lang.t(ex.message), {
          variant: 'error'
        });
      }
    }
  };

  const hendleRegisterForm = async (ev) => {
    ev.persist();
    ev.preventDefault();
  
    if (!isSubmitting.current) {
      try {
        const errors = {};
        const firstName = $(ev.target).find('[name="first_name"]').val();
        const lastName = $(ev.target).find('[name="last_name"]').val();
        const email = $(ev.target).find('[name="email"]').val();
        const password = $(ev.target).find('[name="password"]').val();
        const googleCaptchaVersion = +($(ev.target).find('[name="googleCaptchaVersion"]').val() || 3);
        const googleCaptchaToken = googleCaptchaVersion === 3 ? await userService.getCaptchToken() : $(ev.target).find('[name="googleCaptchaV2Token"]').val();
    
        if (!firstName) errors.first_name = 'First name is required';
        if (!lastName) errors.last_name = 'Last name is required';
        if (!email) errors.email = 'Email is required';
        if (!password) errors.password = 'Password is required';
        else if (password.length < 7) errors.password = 'Password length minimum 7 symbols';

        if (!googleCaptchaToken) {
          enqueueSnackbar(lang.t('Please pass verification'), {
            variant: 'error'
          });
        }
    
        if (Object.keys(errors).length) {
          setFormsState({ ...formsState, errors });
        } else if (googleCaptchaToken) {
          isSubmitting.current = true;
          setFormsState({ ...formsState, errors: {} });
          try {
            const query = Helper.getQueryData();

            if (onBeforeAuth) onBeforeAuth();

            await dispatch(register({ firstName, lastName, email, password, googleCaptchaVersion, googleCaptchaToken }));

            if (query.redirect) history.push(lang.l(query.redirect));
          } catch (ex) {
            let _errors = {};
            try { _errors = JSON.parse(ex.message); } catch (ex) {}
            const { common, ...errors } = _errors.errors || {};

            if (Object.keys(errors).length) setFormsState({ ...formsState, errors });
            if (common) {
              enqueueSnackbar(lang.t(common), {
                variant: 'error'
              });
            }

            if (_errors.captchaV3Error && googleCaptchaVersion === 3) {
              $(ev.target).find('[name="googleCaptchaVersion"]').val(2);

              userService.createCaptchaV2({
                element: $(ev.target).find('.google-captcha-v2')[0],
                callback: (t) => $(ev.target).find('[name="googleCaptchaV2Token"]').val(t),
                expiredCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
                errorCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
              });
            } else if (googleCaptchaVersion === 2) {
              $(ev.target).find('[name="googleCaptchaV2Token"]').val('');
              userService.reloadCaptchaV2();
            }
          } finally {
            isSubmitting.current = false;
          }
        }
      } catch (ex) {
        enqueueSnackbar(lang.t(ex.message), {
          variant: 'error'
        });
      }
    }
  };

  const hendleResetPasswordForm = async (ev) => {
    ev.persist();
    ev.preventDefault();
  
    if (!isSubmitting.current) {
      try {
        const errors = {};
        const email = $(ev.target).find('[name="email"]').val();
        const googleCaptchaVersion = +($(ev.target).find('[name="googleCaptchaVersion"]').val() || 3);
        const googleCaptchaToken = googleCaptchaVersion === 3 ? await userService.getCaptchToken() : $(ev.target).find('[name="googleCaptchaV2Token"]').val();
    
        if (!email) errors.email = 'Email is required';
        if (!googleCaptchaToken) {
          enqueueSnackbar(lang.t('Please pass verification'), {
            variant: 'error'
          });
        }
    
        if (Object.keys(errors).length) {
          setFormsState({ ...formsState, errors });
        } else if (googleCaptchaToken) {
          isSubmitting.current = true;
          setFormsState({ ...formsState, errors: {} });
    
          axios
            .post(`${API_URI}/user/resetPassword`, { email, googleCaptchaVersion, googleCaptchaToken, lang: lang.currentLanguage._id, localization: lang.currentLocalizationIndex, domain: window.location.origin }, { withCredentials: true })
            .then(({ data }) => {
              try {
                if ('success' in data && data.success) {
                  enqueueSnackbar(lang.t('A letter has been sent to your email with instructions to reset your password'), {
                    variant: 'success'
                  });
                } else if ('errors' in data && Object.keys(data.errors).length) {
                  const { common, ...errors } = data.errors;

                  if (Object.keys(errors).length) setFormsState({ ...formsState, errors });
                  if (common) {
                    enqueueSnackbar(lang.t(common), {
                      variant: 'error'
                    });
                  }

                  if (data.captchaV3Error && googleCaptchaVersion === 3) {
                    $(ev.target).find('[name="googleCaptchaVersion"]').val(2);

                    userService.createCaptchaV2({
                      element: $(ev.target).find('.google-captcha-v2')[0],
                      callback: (t) => $(ev.target).find('[name="googleCaptchaV2Token"]').val(t),
                      expiredCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
                      errorCallback: () => $(ev.target).find('[name="googleCaptchaV2Token"]').val(''),
                    });
                  } else if (googleCaptchaVersion === 2) {
                    $(ev.target).find('[name="googleCaptchaV2Token"]').val('');
                    userService.reloadCaptchaV2();
                  }
                }
                else throw false;
              } catch (ex) {
                enqueueSnackbar(lang.t('Something wrong'), {
                  variant: 'error'
                });
              }
              isSubmitting.current = false;
            });
        }
      } catch (ex) {
        enqueueSnackbar(lang.t(ex.message), {
          variant: 'error'
        });
      }
    }
  };

  const handleAccountRestoreRequest = (ev) => {
    if (ev.target.tagName.toLowerCase() == 'a' && ev.target.getAttribute('href') == '%%LINK%%') {
      ev.preventDefault();

      axios
        .post(`${API_URI}/user/restore-request`, { lang: lang.currentLanguage._id }, { withCredentials: true })
        .then(({ data }) => {
          if (data) {
            enqueueSnackbar(lang.t(data), {
              variant: 'success'
            });
          }

          setFormsState({ ...formsState, errors: {} });
        });
    }
  };

  const handleGoogleAuth = (ev) => {
    ev.preventDefault();

    activeSocailData = { onBeforeAuth };

    googleAuth({ type: 'login' });
  };

  const handleFacebookAuth = (ev) => {
    ev.preventDefault();

    activeSocailData = { onBeforeAuth };

    facebookAuth({ type: 'login' });
  };

  useEffect(() => {
    if (staticData.appointedAuthFormsMessageListener) staticData.appointedAuthFormsMessageListener++;
    else {
      staticData.appointedAuthFormsMessageListener = 1;
      staticData.authFormsMessageListener = (ev) => {
        if (ev.origin.startsWith(API_URI)) {
          try {
            const data = JSON.parse(ev.data);
    
            if (data.type == 'makeLogin' && data.data) {
              if (activeSocailData.onBeforeAuth) activeSocailData.onBeforeAuth();

              dispatch(setUserData(data.data, true));
    
              let redirect;
              if (window.location.search && (redirect = window.location.search.match(/(?:\?|&)redirect=([^&]+)/))) {
                history.push(lang.l(decodeURIComponent(redirect[1])));
              }
            }
          } catch (ex) {}
        }
      };
      window.addEventListener('message', staticData.authFormsMessageListener);
    }

    return () => {
      if (!--staticData.appointedAuthFormsMessageListener) {
        window.removeEventListener('message', staticData.authFormsMessageListener);
        delete staticData.appointedAuthFormsMessageListener;
        delete staticData.authFormsMessageListener;
      }
    };
  }, []);

  return (
    <div className={`personal-forms relative place-${place}`}>
      <div className={`personal-login-types${formsState.form != '.personal-login-types' ? ' none' : ''}`}>
        <h2>{lang.t('Sign in.')}</h2>
        <ul>
          <li>
            <a className="block" onClick={handleGoogleAuth} href="#">
              <Google className="inline-block va-middle"/>
              <span className="inline-block va-middle">Google</span>
            </a>
          </li>
          <li>
            <a className="block" onClick={handleFacebookAuth} href="#">
              <Facebook className="inline-block va-middle"/>
              <span className="inline-block va-middle">Facebook</span>
            </a>
          </li>
          <li>
            <a className="block" onClick={(ev) => handleToggleForms(ev, '.personal-login-types', '.personal-login-form')} href="#">
              <Mail className="inline-block va-middle"/>
              <span className="inline-block va-middle">{lang.t('Email')}</span>
            </a>
          </li>
        </ul>
        <div>
          Этот сайт защищен reCAPTCHA. Также действуют Политика конфиденциальности и Условия использования Google.
          <br/>
          Продолжая, Вы соглашаетесь с нашими Условиями использования и подтверждаете, что прочли наше Положение о конфиденциальности и использовании файлов cookie.
        </div>
      </div>
      <form className={`personal-login-form${formsState.form != '.personal-login-form' ? ' none' : ''}`} onSubmit={hendleLoginForm}>
        <a onClick={(ev) => handleToggleForms(ev, '.personal-login-form', '.personal-login-types')} href="#">
          <ArrowBack/>
        </a>
        <div className="form-top">
          {['popup', 'personal'].includes(place) && <a onClick={(ev) => handleToggleForms(ev, '.personal-login-form', '.personal-register-form')} className="fl-right register" href="#">{lang.t('Registration')}</a>}
          <span className="fw-bold">{lang.t('Log in to your account')}:</span>
        </div>
        <label>
          <span>{lang.t('Email')}</span>
          <input className="personal-login-form-email" type="text" name="email"/>
        </label>
        {formsState.form == '.personal-login-form' && formsState.errors.email && <div onClick={handleAccountRestoreRequest} className="form-error form-field-email" style={{ display: 'block' }}>{formsState.errors.email.constructor == Array ? errorFromArray(formsState.errors.email, lang?.currentLanguage?._id) : lang.t(formsState.errors.email)}</div>}
        <a className="forgot-password fl-right" onClick={(ev) => handleToggleForms(ev, '.personal-login-form', '.personal-reset-password-form')} href="#">{lang.t('Forgot password')}</a>
        <label>
          <span>{lang.t('Password')}</span>
          <input className="personal-login-form-password" type="password" name="password"/>
        </label>
        {formsState.form == '.personal-login-form' && formsState.errors.password && <div className="form-error form-field-password" style={{ display: 'block' }}>{lang.t(formsState.errors.password)}</div>}
        <input type="hidden" name="googleCaptchaVersion"/>
        <input type="hidden" name="googleCaptchaV2Token"/>
        <div className="google-captcha-v2"></div>
        <div className="fs-0 submit-remember-me">
          <button className="inline-block va-middle" type="submit">{lang.t('Log in')}</button>
          <label className="inline-block va-middle remember-me">
            <input className="inline-block va-middle" type="checkbox"/>
            <span className="inline-block va-middle">{lang.t('Remember')}</span>
          </label>
        </div>
        {place == 'footer' && <a onClick={(ev) => handleToggleForms(ev, '.personal-login-form', '.personal-register-form')} className="register inline-block" href="#">{lang.t('Registration')}</a>}
      </form>
      <form onSubmit={hendleRegisterForm} className={`personal-register-form${formsState.form != '.personal-register-form' ? ' none' : ''}`}>
        <a onClick={(ev) => handleToggleForms(ev, '.personal-register-form', '.personal-login-types')} href="#">
          <ArrowBack/>
        </a>
        <div className="form-top">
          {['popup', 'personal'].includes(place) && <a onClick={ev => handleToggleForms(ev, '.personal-register-form', '.personal-login-form')} className="fl-right login" href="#">{lang.t('Log in to your account')}</a>}
          <span className="fw-bold">{lang.t('Registration')}:</span>
        </div>
        <label>
          <span>{lang.t('First name')}</span>
          <input className="personal-register-form-first_name" type="text" name="first_name"/>
        </label>
        {formsState.form == '.personal-register-form' && formsState.errors.first_name && <div className="form-error form-field-first_name" style={{ display: 'block' }}>{lang.t(formsState.errors.first_name)}</div>}
        <label>
          <span>{lang.t('Last name')}</span>
          <input className="personal-register-form-name" type="text" name="last_name"/>
        </label>
        {formsState.form == '.personal-register-form' && formsState.errors.last_name && <div className="form-error form-field-last_name" style={{ display: 'block' }}>{lang.t(formsState.errors.last_name)}</div>}
        <label>
          <span>{lang.t('Email')}</span>
          <input className="personal-register-form-email" type="text" name="email"/>
        </label>
        {formsState.form == '.personal-register-form' && formsState.errors.email && <div onClick={handleAccountRestoreRequest} className="form-error form-field-email" style={{ display: 'block' }}>{formsState.errors.email.constructor == Array ? errorFromArray(formsState.errors.email, lang?.currentLanguage?._id) : lang.t(formsState.errors.email)}</div>}
        <label>
          <span>{lang.t('Password')}</span>
          <input className="personal-register-form-password" type="password" name="password"/>
        </label>
        {formsState.form == '.personal-register-form' && formsState.errors.password && <div className="form-error form-field-password" style={{ display: 'block' }}>{lang.t(formsState.errors.password)}</div>}
        <input type="hidden" name="googleCaptchaVersion"/>
        <input type="hidden" name="googleCaptchaV2Token"/>
        <div className="google-captcha-v2"></div>
        <button className="inline-block va-middle" type="submit">{lang.t('Register')}</button>
        {place == 'footer' && <a onClick={ev => handleToggleForms(ev, '.personal-register-form', '.personal-login-form')} className="inline-block login" href="#">{lang.t('Log in to your account')}</a>}
      </form>
      <form className={`personal-reset-password-form${formsState.form != '.personal-reset-password-form' ? ' none' : ''}`} onSubmit={hendleResetPasswordForm}>
        <a onClick={(ev) => handleToggleForms(ev, '.personal-reset-password-form', '.personal-login-types')} href="#">
          <ArrowBack/>
        </a>
        <div className="form-top">
          {['popup', 'personal'].includes(place) && <a onClick={(ev) => handleToggleForms(ev, '.personal-reset-password-form', '.personal-login-form')} className="fl-right login" href="#">{lang.t('Log in to your account')}</a>}
          <span className="fw-bold">{lang.t('Reset password')}:</span>
        </div>
        <label>
          <span>{lang.t('Email')}</span>
          <input className="personal-reset-form-email" type="text" name="email"/>
        </label>
        {formsState.form == '.personal-reset-password-form' && formsState.errors.email && <div className="form-error form-field-email" style={{ display: 'block' }}>{formsState.errors.email.constructor == Array ? errorFromArray(formsState.errors.email, lang?.currentLanguage?._id) : lang.t(formsState.errors.email)}</div>}
        <input type="hidden" name="googleCaptchaVersion"/>
        <input type="hidden" name="googleCaptchaV2Token"/>
        <div className="google-captcha-v2"></div>
        <button className="inline-block va-middle" type="submit">{lang.t('Reset password')}</button>
        {place == 'footer' && <a onClick={(ev) => handleToggleForms(ev, '.personal-reset-password-form', '.personal-login-form')} className="inline-block login" href="#">{lang.t('Log in to your account')}</a>}
      </form>
    </div>
  );
}

export default AuthForms;

export const useHendleLogout = () => {
  const dispatch = useDispatch();

  return async (ev) => {
    ev.preventDefault();
    await dispatch(logout());
  };
};