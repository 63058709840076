import React, { useState, useRef } from 'react';
import ResizeSensible from './ResizeSensible';
import ScrollHidden from './ScrollHidden';

export default ({ tabs, currentTab, onChange, className, multiRows = false }) => {
  const [firstInRow, setFirstInRow] = useState([]);
  const tabsElement = useRef();

  const handleChange = (ev) => {
    ev.preventDefault();

    let a = ev.target;
    while (a.tagName.toLowerCase() !== 'a') a = a.parentNode;

    if (a.tagName === 'A') onChange(a.getAttribute('data-id'));
  };

  const handleResize = () => {
    if (tabsElement.current) {
      const tabsStyle = window.getComputedStyle(tabsElement.current);
      const tabsWidth = tabsElement.current.getBoundingClientRect().width - parseFloat(tabsStyle.getPropertyValue('padding-left')) - parseFloat(tabsStyle.getPropertyValue('padding-right'));
      const tabsList = tabsElement.current.getElementsByTagName('li');
      const indent = 36;
      let width = 0;
      const firstInRow = [];

      for (let i = 0; i < tabsList.length; i++) {
        const tab = tabsList[i].getElementsByTagName('a')[0];
        const style = window.getComputedStyle(tab);
        const thisWidth = tab.getBoundingClientRect().width - parseFloat(style.getPropertyValue('padding-left')) - parseFloat(style.getPropertyValue('padding-right'));

        if (i) {
          if (width + thisWidth + indent <= tabsWidth) {
            width += thisWidth + indent;
          } else {
            width = thisWidth;
            firstInRow.push(i);
          }
        } else {
          width = thisWidth;
        }
      }

      setFirstInRow(firstInRow);
    }
  };

  if (!tabs || !tabs.length) return '';
  else if (multiRows) {
    return (
      <ResizeSensible callback={handleResize} className={`multi-rows-tabs-tabs-wrapper multirows${className ? '' + className : ''}`}>
        <ul ref={tabsElement} className="multi-rows-tabs-tabs clear">
          {tabs.map((t, n) => {
            const className = [];
            if (firstInRow.includes(n)) className.push('first-in-row');
            if (t.id == currentTab) className.push('current');
    
            return (<li {...(className.length ? { className: className.join(' ') } : {})}>
              <ResizeSensible callback={handleResize}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a data-id={t.id} onClick={handleChange} href="#">{t.name}</a>
              </ResizeSensible>
            </li>);
          })}
        </ul>
      </ResizeSensible>
    );
  } else {
    return (
      <ScrollHidden tag="ul" props={{ className: 'multi-rows-tabs-tabs clear' }} arrowsSelector='> li' resizeProps={{ className: 'multi-rows-tabs-tabs-wrapper scrollable' }}>
        {tabs.map((t, n) => {
          const className = [];
          if (t.id == currentTab) className.push('current');
  
          return (<li {...(className.length ? { className: className.join(' ') } : {})}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a data-id={t.id} onClick={handleChange} href="#">{t.name}</a>
          </li>);
        })}
      </ScrollHidden>
    );
  }
};