import useLang from 'src/hooks/useLang';
import { API_URI } from 'src/constants';

let googleAuthAppID;
let facebookAuthAppID;
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.googleAuthAppID) {
  googleAuthAppID = window.__REACT_INIT_DATA__.googleAuthAppID;
  delete window.__REACT_INIT_DATA__.googleAuthAppID;
}
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.facebookAuthAppID) {
  facebookAuthAppID = window.__REACT_INIT_DATA__.facebookAuthAppID;
  delete window.__REACT_INIT_DATA__.facebookAuthAppID;
}

export const useGoogleAuth = () => {
  const lang = useLang('useGoogleAuth');
  
  return ({ type, state = {} }) => {
    const width = 450;
    const height = 450;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const redirectUrl = `${API_URI}/google-auth`;
    const link = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${redirectUrl}&state=${encodeURIComponent(JSON.stringify({ ...state, type, redirectUrl, lang: lang.currentLanguage._id, url: window.location.href }))}&response_type=code&client_id=${googleAuthAppID}&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
    const popupWindow = window.open(link, 'window-popup', 'top=' + top + ',left=' + left + ',location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=yes,toolbar=no,channelmode=yes,fullscreen=yes,width=' + width + ',height=' + height);

    popupWindow.focus();
  };
};

export const useFacebookAuth = () => {
  const lang = useLang('facebookAuth');

  return ({ type, state = {} }) => {
    const width = 450;
    const height = 450;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const link = `https://www.Facebook.com/dialog/oauth?client_id=${facebookAuthAppID}&redirect_uri=${API_URI}/facebook-auth&state=${encodeURIComponent(JSON.stringify({ ...state, type, lang: lang.currentLanguage._id, url: window.location.href }))}&response_type=code&display=popup&scope=email`;
    const popupWindow = window.open(link, 'window-popup', 'top=' + top + ',left=' + left + ',location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=yes,toolbar=no,channelmode=yes,fullscreen=yes,width=' + width + ',height=' + height);

    popupWindow.focus();
  };
};