import React, { useState, useCallback, useEffect, useRef, useContext } from 'react';
import Link from 'src/front/components/Link';
import useLang from 'src/hooks/useLang';
import axios from 'axios';
import { API_URI, MONTHS, IS_SERVER } from 'src/constants';
import Helper from 'src/front/helpers/Helper';
import { useSelector } from 'react-redux';
import { FrontContext } from '../helpers/FrontContext';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import Sticky from 'src/front/components/Sticky';

function LeftSidebar({ staticContext, match, match: { params }, stickyCallback }) {
  const lang = useLang('LeftSidebar');
  const { user } = useSelector(({ account }) => account);
  const { myCities, staticData, dimensionUnits, leftSidebarBottomHook } = useContext(FrontContext);
  const detectedCountry = params.country || user?.countryLink || myCities[0]?.link?.replace(/^\/|\/[^/]*$/g, '') || 'israel';
  const [news, setNews] = useState(
    staticContext && staticContext.news ?
    staticContext.news :
    (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.news ?
      window.__REACT_INIT_DATA__.news :
      (staticData.sidebarNews ? staticData.sidebarNews : null)
    )
  );
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.news) delete window.__REACT_INIT_DATA__.news;
  const isNewsPage = /\/news\//.test(match.path);
  const isMount = useRef(true);

  staticData.sidebarNews = news;

  useEffect(() => {
    if (window.FB) {
      try {
        window.FB.XFBML.parse();
      } catch (ex) {}
    }

    return () => {
      isMount.current = false;
    };
  }, []);

  const getNews = useCallback(() => {
    if (!isNewsPage && (!news || news.country.link !== detectedCountry)) {
      axios
        .get(`${API_URI}/sidebar-news${detectedCountry !== 'israel' ? '?country=' + encodeURIComponent(detectedCountry) : ''}`, { withCredentials: true })
        .then(({ data }) => isMount.current && setNews(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectedCountry, isNewsPage]);

  useEffect(() => {
    getNews();
  }, [getNews]);

  const isLoaded = IS_SERVER || (lang.loaded && (isNewsPage || (news/* && detectedCountry == news.country.link*/)));

  const date = news ? Helper.dateFromFormat(news.news.date, 'Y-m-d') : null;
  const newsRegions = (news && news?.regions) || [];
  const sidebarDistricts = () => <div id="sidebar-districts">
    <h2>{isLoaded && (IS_SERVER || newsRegions.length) ? lang.t('Districts of !country', { '!country': IS_SERVER ? '{print  Helper.getFieldValue(news.country.name_genitive, lang)}' : Helper.getFieldValue(news.country.name_genitive, lang.currentLanguage._id) }) : <span className="content-preloader" style={{ width: '175px', height: '29px' }}></span>}</h2>
    <ul>
      {isLoaded && (IS_SERVER || newsRegions.length) ? (IS_SERVER ? [''] : newsRegions).map((r) => <React.Fragment key={r.link}>
        {IS_SERVER ? '{for (let i = 0; i < news.regions.length; i++) :}' : ''}
        <li>
          {
            (IS_SERVER || lang.isLink(`/${news.country.link}/${r.link}`)) &&
            <>
              {IS_SERVER && `{if (${lang.isLink("{print '/' + news.country.link + '/' + news.regions[i].link}")}) :}`}
              <div>
                <span className="va-middle">{IS_SERVER ? '{print Helper.getFieldValue(news.regions[i].name, lang)}' : Helper.getFieldValue(r.name, lang.currentLanguage._id)}</span>
                <span className="va-middle tempr inline-block">{IS_SERVER ? "{print DimensionUnitsConvertor.temperature(news.regions[i].temp, dimensionUnits.temperature)}" : DimensionUnitsConvertor.temperature(r.temp, dimensionUnits.temperature)}</span>
                <img alt="Weather" width="284" height="284" loading="lazy" className="va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', news.regions[i].icon + '.svg')}" : Helper.getFileUrl('weather-icon', `${r.icon}.svg`)}/>
              </div>
              {IS_SERVER && '{endif}'}
            </>
          }
          {
            (IS_SERVER || !lang.isLink(`/${news.country.link}/${r.link}`)) &&
            <>
              {IS_SERVER && `{if (!(${lang.isLink("{print '/' + news.country.link + '/' + news.regions[i].link}")})) :}`}
              <Link to={lang.l(IS_SERVER ? "{print '/' + news.country.link + '/' + news.regions[i].link}" : `/${news.country.link}/${r.link}`)}>
                <span className="va-middle">{IS_SERVER ? '{print Helper.getFieldValue(news.regions[i].name, lang)}' : Helper.getFieldValue(r.name, lang.currentLanguage._id)}</span>
                <span className="va-middle tempr inline-block">{IS_SERVER ? "{print DimensionUnitsConvertor.temperature(news.regions[i].temp, dimensionUnits.temperature)}" : DimensionUnitsConvertor.temperature(r.temp, dimensionUnits.temperature)}</span>
                <img alt="Weather" width="284" height="284" loading="lazy" className="va-middle" src={IS_SERVER ? "{print Helper.getFileUrl('weather-icon', news.regions[i].icon + '.svg')}" : Helper.getFileUrl('weather-icon', `${r.icon}.svg`)}/>
              </Link>
              {IS_SERVER && '{endif}'}
            </>
          }
        </li>
        {IS_SERVER ? '{endfor}' : ''}
      </React.Fragment>) : ['110px', '140px', '180px', '160px', '170px'].map((r) => <li>
        <span className="content-preloader" style={{ width: r, height: '31px' }}></span>
      </li>)}
    </ul>
    {
      isLoaded && (IS_SERVER || (newsRegions.length && news.country.regions_quantity > newsRegions.length)) ? <>
        {IS_SERVER && '{if (news.country.regions_quantity > news.regions.length) :}'}
        <Link to={lang.l(IS_SERVER ? "{print '/' + news.country.link + '/'}" : `/${news.country.link}/`)}>{lang.t('Show more')}</Link>
        {IS_SERVER && '{endif}'}
      </> : ''
    }
  </div>;

  return (
    <>
      <aside id="left-sidebar">
        <Sticky name="left-sidebar" bottomHook={leftSidebarBottomHook ?? '#banner-300x600'} media="1101-*" exportResizeCallback={stickyCallback} topFixedElement="header">
          {!isNewsPage && <>
            <div className="relative" id="sidebar-news">
              <Link className="sidebar-rss rss-link text-10" to={lang.l('/rss/')}>rss</Link>
              <h2>
                {isLoaded ? <span>{lang.t('Weather news of')} </span> : <span className="content-preloader" style={{ width: '170px', height: '29px' }}></span>}
                {isLoaded ? <span>{lang.localizationText(IS_SERVER ? '{print news.country.name_genitive}' : news.country.name_genitive)}</span> : <span className="content-preloader" style={{ width: '85px', height: '29px' }}></span>}
              </h2>
              {
                isLoaded ?
                <Link
                  className="sidebar-news-title"
                  to={IS_SERVER ? lang.l("{print (() => &lf;const date = Helper.dateFromFormat(news.news.date, 'Y-m-d'); return '/news/weather-in-' + news.country.link + '-' + date.getDate() + MONTHS[date.getMonth()] + date.getFullYear();&rf;)()}") : lang.l(`/news/weather-in-${news.country.link}-${date.getDate()}${MONTHS[date.getMonth()]}${date.getFullYear()}`)}
                  dangerouslySetInnerHTML={{ __html: lang.localizationText(IS_SERVER ? '{print news.news.sidebarTitle}' : news.news.sidebarTitle) }}
                /> :
                <Link className="sidebar-news-title" to="#">
                  <span className="content-preloader" style={{ width: '100%', height: '26px', display: 'block' }}></span>
                </Link>
              }
              {
                isLoaded ?
                <div className="news-text fs-15" dangerouslySetInnerHTML={{ __html: IS_SERVER ? `{print DimensionUnitsConvertor.parse(${lang.localizationText('{print news.news.sidebarText}').replace(/^\{print |\}$/g, '')}, dimensionUnits, [translate, 'LeftSidebar'], true)}` : DimensionUnitsConvertor.parse(lang.localizationText(news.news.sidebarText), dimensionUnits, lang, true) }}/> :
                <div className="news-text fs-15">
                  <span className="content-preloader" style={{ width: '100%', height: '55px' }}></span>
                </div>
              }
              <Link className="news-link fs-15" to={isLoaded ? lang.l(IS_SERVER ? "{print '/news/' + (news.country.link != 'israel' ? news.country.link + '/' : '')}": `/news/${detectedCountry !== 'israel' ? detectedCountry + '/' : ''}`) : '#'}>{isLoaded ? lang.t('News feed') : <span className="content-preloader" style={{ width: '100px', height: '18px', display: 'block' }}></span>}</Link>
            </div>
            {
              IS_SERVER ?
              <>
                {`{if (news && news.regions && news.regions.length) :}`}
                {sidebarDistricts()}
                {`{endif}`}
              </> :
              (!isLoaded || newsRegions.length ? sidebarDistricts() : '')
            }
          </>}
          <div className="banner-wrapper" id="banner-300x250">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="advertising-link" href="#">{isLoaded ? lang.t('Advertising on the site') : <span className="content-preloader" style={{ width: '85px', height: '13px' }}></span>}</a>
            <img width="300" height="250" alt="Banner" style={{display: 'block'}} src="/images/banner_300x250.jpg" loading="lazy"/>
          </div>
          <div className="banner-wrapper" id="banner-300x600">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="advertising-link" href="#">{isLoaded ? lang.t('Advertising on the site') : <span className="content-preloader" style={{ width: '85px', height: '13px' }}></span>}</a>
            <img width="300" height="600" alt="Banner" style={{display: 'block'}} src="/images/banner_300x600.jpg" loading="lazy"/>
          </div>
          {
            (IS_SERVER || lang.currentLocalization.facebookPagePlugin) &&
            <>
              {IS_SERVER && '{if (staticData.language.localizations[localization].facebookPagePlugin) :}'}
              <div className="fb-page" data-href={IS_SERVER ? '{print staticData.language.localizations[localization].facebookPagePlugin}' : lang.currentLocalization.facebookPagePlugin} data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                <blockquote cite={IS_SERVER ? '{print staticData.language.localizations[localization].facebookPagePlugin}' : lang.currentLocalization.facebookPagePlugin} className="fb-xfbml-parse-ignore">
                  <a href={IS_SERVER ? '{print staticData.language.localizations[localization].facebookPagePlugin}' : lang.currentLocalization.facebookPagePlugin}>Weather Stream in world</a>
                </blockquote>
              </div>
              {IS_SERVER && '{endif}'}
            </>
          }
        </Sticky>
      </aside>
    </>
  );
}

export default LeftSidebar;