import Helper from 'src/front/helpers/Helper';
import { IS_SERVER } from 'src/constants';

export default ({ name, page, language, localization, host, protocol, requestUrl, microdata, additional } = {}) => {
  const names = Array.isArray(name) ? name : [name];
  let microdataObject = {
    '@context': 'http://schema.org',
    '@graph': []
  };

  if (!IS_SERVER) {
    host = window.location.host;
    protocol = window.location.protocol;
    requestUrl = window.location.href.substring(host.length + protocol.length + 2);
    microdata = Helper.getMicrodataParams();
  }

  const parseText = (text) => Helper.getLocalizationText(Helper.getFieldValue(text, language._id), language.localizations[localization].shortName).replace(/%%DOMAIN%%/g, Helper.token('DOMAIN', host));
  const getData = (name) => {
    let graph = null;

    switch (name) {
      case 'WebSite': {
        graph = {
          '@type': 'WebSite',
          url: `${protocol}//${host}`,
        };

        if (microdata.projectName) graph.name = microdata.projectName;

        if (microdata.links?.length) {
          graph.sameAs = microdata.links.map((sa) => Helper.getLocalizationText(sa, language.localizations[localization].shortName)).filter((sa) => sa);
          if (!graph.sameAs.length) delete graph.sameAs;
        }
        break;
      }

      case 'Map': {
        graph = {
          '@type': 'Map',
          name: parseText(page.title),
          url: `${protocol}//${host}${requestUrl}`,
        };
        break;
      }

      case 'ContactPage': {
        graph = {
          '@type': 'ContactPage',
          description: parseText(page.description),
          isPartOf: {
            '@type': 'WebSite',
            url: `${protocol}//${host}`,
          },
        };

        if (microdata.projectName) graph.name = microdata.projectName;

        if (microdata.links?.length) {
          graph.sameAs = microdata.links.map((sa) => Helper.getLocalizationText(sa, language.localizations[localization].shortName)).filter((sa) => sa);
          if (!graph.sameAs.length) delete graph.sameAs;
        }
        break;
      }

      case 'Article': {
        graph = {
          '@type': 'Article',
          headline: parseText(page.title),
          datePublished: additional.article.date,
          dateModified: additional.article.date,
          isPartOf: getData('WebPage')
        };
        break;
      }

      case 'WebPage': {
        graph = {
          '@type': 'WebPage',
          url: `${protocol}//${host}${requestUrl}`,
          inLanguage: language.localizations[localization].shortName,
          name: parseText(page.title),
          datePublished: additional.article.date,
          dateModified: additional.article.date,
          description: parseText(page.description),
          isPartOf: getData('WebSite')
        };
        break;
      }

      case 'BreadcrumbList': {
        graph = {
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              'position': 1,
              item: {
                '@id': `${protocol}//${host}`,
                name: microdata.projectName
              }
            },
            {
              '@type': 'ListItem',
              'position': 2,
              item: {
                '@id': `${protocol}//${host}${requestUrl}`,
                name: parseText(page.title)
              }
            }
          ]
        };
      }
    }

    return graph;
  };

  for (const name of names) {
    const graph = getData(name);

    if (graph) microdataObject['@graph'].push(graph);
  }

  if (IS_SERVER) return microdataObject['@graph'].length ? microdataObject : null;
  else {
    if (microdataObject['@graph'].length) {
      let script = document.querySelector('script[type="application/ld+json"]');

      if (!script) {
        script = document.createElement('script');
        script.setAttribute('type', 'application/ld+json');
        document.head.appendChild(script);
      }

      script.innerHTML = JSON.stringify(microdataObject);
    } else {
      const script = document.querySelector('script[type="application/ld+json"]');
      if (script) script.remove();
    }
  }
};