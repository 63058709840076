import React from 'react';
import { IS_SERVER } from 'src/constants';

function Navigation({ pager, page, setPage, className, disabled = false }) {
  const handleSetPage = (ev, p) => {
    ev.preventDefault();
    if (p.page && !disabled) setPage(p.page);
  };

  return (
    (IS_SERVER || (pager && pager.length > 3)) &&
    <>
      {IS_SERVER && `{if (${pager} && ${pager}.length > 3) :}`}
      <div className={`navigation-wrapper${className ? ' ' + className : ''}`}>
        <ul>
          {(IS_SERVER ? [''] : pager).map((p) => <>
            {IS_SERVER && `{for (let i = 0; i < ${pager}.length; i++) :}`}
            <li>
              {
                IS_SERVER ?
                `
                  {if (${pager}[i].title == '<') :}
                    {literal}<a aria-label="Previous page" class="navigation-prev{if (!${pager}[i].page || ${disabled}) :} disabled{endif}" href="#"></a>{/literal}
                  {endif}
                ` :
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                (p.title === '<' && <a aria-label="Previous page" className={`navigation-prev${!p.page || disabled ? ' disabled' : ''}`} onClick={(ev) => handleSetPage(ev, p)} href="#"></a>)
              }
              {
                IS_SERVER ?
                `
                  {if (${pager}[i].title == '>') :}
                    {literal}<a aria-label="Next page" class="navigation-next{if (!${pager}[i].page || ${disabled}) :} disabled{endif}" href="#"></a>{/literal}
                  {endif}
                ` :
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                (p.title === '>' && <a aria-label="Next page" className={`navigation-next${!p.page || disabled ? ' disabled' : ''}`} onClick={(ev) => handleSetPage(ev, p)} href="#"></a>)
              }
              {
                IS_SERVER ?
                `
                  {if (!['<', '>'].includes(${pager}[i].title)) :}
                    {if (${pager}[i].page == ${page}) :}
                      {literal}<span{if (${disabled}) :} class="disabled"{endif}><span>{print ${pager}[i].title}</span></span>{/literal}
                    {else :}
                      {literal}<a{if (${disabled}) :} class="disabled"{endif} href="#"><span>{print ${pager}[i].title}</span></a>{/literal}
                    {endif}
                  {endif}
                ` :
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                (!['<', '>'].includes(p.title) && (p.page === page ? <span {...disabled && { className: 'disabled' }}><span>{p.title}</span></span> : <a {...disabled && { className: 'disabled' }} onClick={(ev) => handleSetPage(ev, p)} href="#"><span>{p.title}</span></a>))
              }
            </li>
            {IS_SERVER && '{endfor}'}
          </>)}
        </ul>
      </div>
      {IS_SERVER && '{endif}'}
    </>
  );
}

export default Navigation;