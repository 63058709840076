import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import useStyles from 'src/hooks/useStyles';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

import styles from './style.css';

const defaultBackground = 'rgba(17, 41, 86, 0.35)';

function Popup({
	children, anchor, isOpened, onClose, anchorOrigin, popupOrigin, offset, className, style, popupRef,
	clearBackgroundUnderPopup = true, drawBackgroundElement, ...rest
}) {
	const [state, setState] = useState({ visible: false, render: false, background: defaultBackground, style: {} });
	const popupElement = useRef(null);
	const timer = useRef(null);
	const stateRef = useRef(null);
	const resizeHandler = useRef(() => setState({ ...stateRef.current, style: calculatePosition.current() }));
	const calculatePosition = useRef(null);
	const isMounted = useIsMountedRef();
	const resizeObserverRef = useRef(typeof ResizeObserver !== 'undefined' ? new ResizeObserver(() => {
		if (popupElement.current) {
			const style = calculatePosition.current();

			if (stateRef.current?.style?.left !== style.left || stateRef.current?.style?.top !== style.top) setState({ ...stateRef.current, style });
		}
	}) : null);

	if (popupRef) popupRef.current = popupElement;

	stateRef.current = state;
	calculatePosition.current = () => {
		const anchorBoundingClientRect = anchor === 'viewport' ? { x: 0, y: 0, width: document.documentElement.clientWidth, height: document.documentElement.clientHeight } : anchor.getBoundingClientRect();
		const popupBoundingClientRect = popupElement.current.getBoundingClientRect();
		let left = anchorOrigin.horizontal === 'left' ? anchorBoundingClientRect.x : (anchorOrigin.horizontal === 'right' ? anchorBoundingClientRect.x + anchorBoundingClientRect.width : anchorBoundingClientRect.x + anchorBoundingClientRect.width / 2);
		let top = anchorOrigin.vertical === 'top' ? anchorBoundingClientRect.y : (anchorOrigin.vertical === 'bottom' ? anchorBoundingClientRect.y + anchorBoundingClientRect.height : anchorBoundingClientRect.y + anchorBoundingClientRect.height / 2);

		switch (popupOrigin.horizontal) {
			case 'right': left -= popupBoundingClientRect.width; break;
			case 'center': left -= popupBoundingClientRect.width / 2; break;
		}

		switch (popupOrigin.vertical) {
			case 'bottom': top -= popupBoundingClientRect.height; break;
			case 'center': top -= popupBoundingClientRect.height / 2; break;
		}

		return {
			left: left + (offset?.left || 0),
			top: top + (offset?.top || 0),
		};
	};

	useStyles(styles);

	useEffect(() => {
		if (isOpened || stateRef.current.render) {
			if (timer.current) {
				clearTimeout(timer.current);
				timer.current = null;
			}

			if (isOpened && !stateRef.current.render) {
				document.body.style.setProperty('--scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`);
				document.body.classList.add('popup-opened');

				stateRef.current.style = calculatePosition.current();

				if (resizeObserverRef.current) resizeObserverRef.current.observe(popupElement.current);
			}

			if (clearBackgroundUnderPopup && stateRef.current.background === defaultBackground) {
				const backgroundElement = drawBackgroundElement ? (typeof drawBackgroundElement === 'string' ? popupElement.current.querySelector(`:scope ${drawBackgroundElement}`) : drawBackgroundElement) : popupElement.current;
				const position = { ...stateRef.current.style };

				if (drawBackgroundElement) {
					const popupRect = popupElement.current.getBoundingClientRect();
					const backgroundElementRect = backgroundElement.getBoundingClientRect();

					position.top += backgroundElementRect.top - popupRect.top;
					position.left += backgroundElementRect.left - popupRect.left;
				}

				stateRef.current.background = `url(${drawBackground(backgroundElement, position)}) 0 0 no-repeat`;
			}

			if (isOpened) {
				setState({ ...stateRef.current, visible: true, render: true });

				timer.current = setTimeout(() => {
					if (isMounted.current) setState({ ...stateRef.current, visible: true, render: true, background: defaultBackground });
				}, 300);
			} else {
				setState({ ...stateRef.current, visible: false, render: true });

				timer.current = setTimeout(() => {
					document.body.removeAttribute('style');
					document.body.classList.remove('popup-opened');
					if (isMounted.current) setState({ ...stateRef.current, visible: false, render: false, background: defaultBackground });
				}, 300);
			}
		}
	}, [isOpened]);

	useEffect(() => {
		if (isOpened || stateRef.current.render) window.addEventListener('resize', resizeHandler.current);
		else {
			window.removeEventListener('resize', resizeHandler.current);

			if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
		}

		return () => window.removeEventListener('resize', resizeHandler.current);
	}, [isOpened || state.render]);

	return (
		(isOpened || state.render) &&
		<>
			<PopupBackground>
				<div {...onClose && { onClick: onClose }} className={`popup-background${state.visible ? ' visible' : ''}`} style={{ background: state.background }}>
				</div>
				<div {...rest} ref={popupElement} className={`popup${state.visible ? ' visible' : ''}${className ? ' ' + className : ''}`} style={{ ...style, ...state.style }}>
					{children}
				</div>
			</PopupBackground>
		</>
	);
}

const PopupBackground = ({ children }) => ReactDOM.createPortal(children, document.body);

const drawBackground = (element, { left, top }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const boundingClientRect = element.getBoundingClientRect();
  const styles = window.getComputedStyle(element);
  const clearRadius = parseFloat(styles.getPropertyValue('border-top-left-radius')) * 2;

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  ctx.fillStyle = defaultBackground;
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = '#fff';
  ctx.lineJoin = 'round';
  ctx.lineWidth = clearRadius;
  ctx.globalCompositeOperation = 'destination-out';

  ctx.strokeRect(left + (clearRadius / 2), top + (clearRadius / 2), boundingClientRect.width - clearRadius, boundingClientRect.height - clearRadius);
  ctx.fillRect(left + (clearRadius / 2), top + (clearRadius / 2), boundingClientRect.width - clearRadius, boundingClientRect.height - clearRadius);

  return canvas.toDataURL();
};

export default Popup;