import Helper from 'src/front/helpers/Helper';

const eventsWithResponse = [];
let serviceWorker = null;
// let isSubscribed = false;
const requests = {};

async function getServiceWorker() {
  if (serviceWorker?.state !== 'activated') {
    if (typeof window !== 'object' || window === null || !('serviceWorker' in navigator)) throw new Error('serviceWorker is not unavailable');

    const registrations = await navigator.serviceWorker.getRegistrations();

    for (const registration of registrations) {
      if (registration?.active?.scriptURL?.endsWith('/firebase-messaging-sw.js')) {
        serviceWorker = registration.active;
      }
    }
  }

  if (serviceWorker?.state !== 'activated') throw new Error('Service worker not found');

  return serviceWorker;
}

function messageHandler(ev) {
  if (ev?.data?.type === 'firebaseMessageResponse' && ev.data.requestId in requests) {
    if (ev.data.requestId in requests) {
      clearTimeout(requests[ev.data.requestId].timer);
      requests[ev.data.requestId].resolve(ev.data.data);
      delete requests[ev.data.requestId];
    }
  }
}

if (typeof navigator !== 'undefined' && navigator?.serviceWorker?.addEventListener) {
  navigator.serviceWorker.addEventListener('message', messageHandler);
}

export default {
  // get isSubscribed() {
  //   return isSubscribed;
  // },
  // subscribe() {
  //   if (typeof navigator !== 'undefined' && navigator?.serviceWorker?.addEventListener) {
  //     navigator.serviceWorker.addEventListener('message', messageHandler);
  //     isSubscribed = true;
  //   }
  // },
  // unsubscribe() {
  //   if (typeof navigator !== 'undefined' && navigator?.serviceWorker?.addEventListener) {
  //     navigator.serviceWorker.removeEventListener('message', messageHandler);
  //     isSubscribed = false;
  //   }
  // },
  postMessage(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const sw = await getServiceWorker();

        if (eventsWithResponse.includes(data.type)) {
          let requestId = null;

          while (!requestId || requestId in requests) requestId = Helper.hash(5);

          requests[requestId] = {
            resolve,
            timer: setTimeout(() => {
              delete requests[requestId];
              reject(new Error('Request time is up'));
            }, 5000)
          };
          data.requestId = requestId;

          sw.postMessage(data);
        } else {
          sw.postMessage(data);
          resolve();  
        }
      } catch (ex) {reject(ex);}
    });
  }
};