import React, { useState, useEffect, useRef } from 'react';
import LeftSidebar from 'src/front/components/LeftSidebar.js';
import RightSidebar from 'src/front/components/RightSidebar.js';
import ResizeSensible from 'src/front/components/ResizeSensible';
import Sticky from 'src/front/components/Sticky';
import { IS_SERVER } from 'src/constants';

function MainLayout({ children, afterMain = null, ...rest }) {
  const [afterMainInMain, setAfterMainInMain] = useState(!IS_SERVER && window.innerWidth > 1100);
  const leftSidebarStickyCallback = useRef(null);
  const rightSidebarStickyCallback = useRef(null);
  const stickyCallback = useRef(null);

  // const handleResizeSensible = () => {
  //   if (leftSidebarStickyCallback.current) leftSidebarStickyCallback.current();
  //   if (rightSidebarStickyCallback.current) rightSidebarStickyCallback.current();
  //   if (stickyCallback.current) stickyCallback.current();
  // };

  useEffect(() => {
    const resizeHandler = () => {
      if (afterMain) {
        if (window.innerWidth > 1100) setAfterMainInMain(true);
        else setAfterMainInMain(false);
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <>
      <RightSidebar {...rest} stickyCallback={leftSidebarStickyCallback}/>
      <main>
        <Sticky name="main" media="1101-*" exportResizeCallback={stickyCallback} topFixedElement="header">
          {children}
          {afterMainInMain && afterMain}
        </Sticky>
      </main>
      <LeftSidebar {...rest} stickyCallback={rightSidebarStickyCallback}/>
      {!afterMainInMain && afterMain}
    </>
  );
  // return (
  //   <ResizeSensible className="clear" callback={handleResizeSensible}>
  //     <RightSidebar {...rest} stickyCallback={leftSidebarStickyCallback}/>
  //     <main>
  //       <Sticky media="1101-*" exportResizeCallback={stickyCallback} topFixedElement="header">
  //         {children}
  //         {afterMainInMain && afterMain}
  //       </Sticky>
  //     </main>
  //     <LeftSidebar {...rest} stickyCallback={rightSidebarStickyCallback}/>
  //     {!afterMainInMain && afterMain}
  //   </ResizeSensible>
  // );
}

export default MainLayout;