import React, { useState, useEffect, useRef } from 'react';
import { IS_SERVER } from 'src/constants';
import Helper from 'src/front/helpers/Helper';

const activeStyles = { id: {}, src: {} };

function ComponentStyle({ children, styles, Preloader }) {
  const stylesExists = useRef(null);
  if (!IS_SERVER) {
    if (styles) styles = styles.constructor === Array ? styles : [styles];
    else styles = [];
  }

  const getComponentStyleTags = () => document.head.querySelectorAll(':scope > style.component-style, :scope > link[rel="stylesheet"].component-style');

  if (!IS_SERVER && stylesExists.current === null) {
    const tags = getComponentStyleTags();

    stylesExists.current = 0;

    for (let t = 0; t < tags.length; t++) {
      const tagName = tags[t].tagName.toLowerCase();
      const tagHref = tags[t].getAttribute('href');
      const tagId = tags[t].getAttribute('id');

      for (let s = 0; s < styles.length; s++) {
        if ((tagName === 'link' && styles[s].src && tagHref === styles[s].src) || (tagName === 'style' && styles[s].id && styles[s].id === tagId)) {
          if (stylesExists.current < styles.length) stylesExists.current++;
          break;
        }
      }
    }

    styles.forEach((s) => {
      if (s.src) {
        if (s.src in activeStyles.src) activeStyles.src[s.src]++;
        else activeStyles.src[s.src] = 1;
      } else if (s.id) {
        if (s.id in activeStyles.id) activeStyles.id[s.id]++;
        else activeStyles.id[s.id] = 1;
      }
    });
  }

  const [loaded, setLoaded] = useState(stylesExists.current === styles.length || IS_SERVER);

  useEffect(() => {
    const tags = getComponentStyleTags();
  
    styles.forEach((() => {
      let stylesLoaded = 0;

      return (s, i) => {
        let style;
        let styleExists = true;

        try {
          for (let t = 0; t < tags.length; t++) {
            if ((s.src && tags[t].tagName.toLowerCase() === 'link' && tags[t].getAttribute('href') === s.src) ||
                (s.id && tags[t].tagName.toLowerCase() === 'style' && tags[t].getAttribute('id') === s.id)) {
              style = tags[t];
              throw new Error();
            }
          }

          if (s.src) {
            style = document.createElement('link');
            style.onload = style.onerror = function () {
              if (++stylesLoaded === styles.length) {
                setLoaded(true);
              }
            };
            style.href = s.src;
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.className = 'component-style';
          } else {
            style = document.createElement('style');
            style.id = s.id;
            style.innerHTML = s.content;
            style.className = 'component-style';

            if (++stylesLoaded === styles.length) {
              setLoaded(true);
            }
          }

          styleExists = false;
        } catch (ex) {
          if (++stylesLoaded === styles.length) {
            setLoaded(true);
          }
        }

        if (!styleExists) {
          const componentStyles = getComponentStyleTags();
          let reference = null;

          if (componentStyles.length) reference = componentStyles[componentStyles.length - 1].nextSibling;
          else {
            const otherStyles = document.head.querySelectorAll(':scope > style, :scope > link[rel="stylesheet"]');
            if (otherStyles.length) reference = otherStyles[otherStyles.length - 1].nextSibling;
          }

          if (reference) document.head.insertBefore(style, reference);
          else document.head.appendChild(style);
        }
      };
    })());

    return () => {
      const tags = getComponentStyleTags();

      for (let t = tags.length; t--;) {
        const tagName = tags[t].tagName.toLowerCase();
        const tagHref = tags[t].getAttribute('href');
        const tagId = tags[t].getAttribute('id');
  
        for (let s = 0; s < styles.length; s++) {
          if ((tagName === 'link' && styles[s].src && tagHref === styles[s].src) || (tagName === 'style' && styles[s].id && styles[s].id === tagId)) {
            try {
              if (styles[s].src) {
                activeStyles.src[styles[s].src]--;
                if (!activeStyles.src[styles[s].src]) {
                  delete activeStyles.src[styles[s].src];
                  throw new Error();
                }
              } else {
                activeStyles.id[styles[s].id]--;
                if (!activeStyles.id[styles[s].id]) {
                  delete activeStyles.id[styles[s].id];
                  throw new Error();
                }
              }
            } catch (ex) {
              tags[t].parentNode.removeChild(tags[t]);
            }
            break;
          }
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {
        IS_SERVER && styles &&
        `{exec}
          dynamicValue = ${typeof styles == 'string' ? styles : Helper.objectToString(styles)};
          if (dynamicValue) {
            if (dynamicValue.constructor != Array) dynamicValue = [dynamicValue];

            if (!('customStyle' in context)) context.customStyle = [];

            dynamicValue.forEach((s) => !context.customStyle.filter((e) => Helper.compare(e, s)).length && context.customStyle.push(s));
          }
        {/exec}`
      }
      {!loaded && Preloader !== undefined ? Preloader : children}
    </>
  );
}

export default ComponentStyle;