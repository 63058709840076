import Helper from 'src/front/helpers/Helper';

export default {
	getCloudinessKey: function (value) {
		if (!Helper.isNumeric(value) || value < 26) {
      return 1;
    } else if (value > 25 && value < 51) {
      return 2;
    } else if (value > 50 && value < 76) {
      return 3;
    } else {
      return 4;
    }
	},

	getTemperatureDescriptionKey: function (todayTemp, yesterdayTemp) {
		if (Helper.isNumeric(todayTemp)) {
      if (Helper.isNumeric(yesterdayTemp)) {
        const dif = yesterdayTemp - todayTemp;

        if (dif < -5) {
          return 7;
        } else if (dif > -6 && dif < -1) {
          return 6;
        } else if (dif > 1 && dif < 6) {
          return 5;
        } else if (dif > 5) {
          return 4;
        } else {
          return 8;
        }
      } else {
        if (todayTemp < 19) {
          return 1;
        } else if (todayTemp > 18 && todayTemp < 30) {
          return 2;
        } else {
          return 3;
        }
      }
    } else return 1;
	},

	getPressureDescriptionKey: function (todayValue, yesterdayValue) {
		const dif = yesterdayValue - todayValue;

    if (dif > 7) {
      return 1;
    } else if (dif > 2 && dif < 8) {
      return 2;
    } else if (dif < -2 && dif > -8) {
      return 4;
    } else if (dif < -7) {
       return 5;
    } else {
      return 3;
    }
	},

	getThunderstormPossibilityKey: function (value) {
		if (!Helper.isNumeric(value) || value < 26) {
      return 1;
    } else if (value > 25 && value < 51) {
      return 2;
    } else if (value > 50 && value  < 76) {
      return 3;
    } else {
      return 4;
    }
	},

	getPrecipitationPossibilityKey: function (precipitation, precipitationPossibility) {
		let key = null;

    if (!Helper.isNumeric(precipitation) || precipitation <= 1) {
      key = 0;
    } else if (precipitation > 1 && precipitation < 5) {
      key = 1;
    } else if (precipitation > 4 && precipitation < 16) {
      key = 4;
    } else if (precipitation > 15) {
      key = 7;
    }

    if (key && Helper.isNumeric(precipitationPossibility)) {
      if (precipitationPossibility > 50 && precipitationPossibility < 76) {
        key += 1;
      } else if (precipitationPossibility > 75) {
        key += 2;
      }
    }

    return key;
	},

  getTempColor: function (temp) {
    if (temp < 21) return '#5B8DE6';
    if (temp > 20 && temp < 36) return '#F47500';
    return '#F65E5D';
  }
}