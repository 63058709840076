import React from 'react';
import { IS_SERVER } from 'src/constants';
import Helper from 'src/front/helpers/Helper';

const colorVariants = [
  ['#ffebcf', '#ffd096', '#e6d9c5', '#bd6100'],
  ['#ffdeda', '#fdc6c2', '#e8bfbb', '#cc0700'],
  ['#eafcda', '#d1f6b4', '#bcdca2', '#00853e'],
  ['#e3ecfc', '#c8def4', '#b7cadf', '#585dc1'],
  ['#f5e8fb', '#ebd9f5', '#d3c4dc', '#7719aa'],
  ['#ffeaf2', '#ffe0ed', '#e5cad5', '#c41d7f'],
];

function Avatar({ className, src, firstName, lastName, width = 42, height = 42, fontSize = 20, style = {}, ...rest }) {
  let code = 0;

  if (firstName) code += firstName.charCodeAt(0);
  if (lastName) code += lastName.charCodeAt(0);

  const colors = colorVariants[code % colorVariants.length];
  const styles = {
    image: {
      objectFit: 'cover',
      borderRadius: style.borderRadius || '50%',
      width: `${width}px`,
      height: `${height}px`,
      display: 'block',
      ...style,
    },
    parent: {
      background: `linear-gradient(to right, ${IS_SERVER ? '{print dynamicValue[0]}' : colors[0]} 0%, ${IS_SERVER ? '{print dynamicValue[1]}' : colors[1]} 100%)`,
      border: `1px solid ${IS_SERVER ? '{print dynamicValue[2]}' : colors[2]}`,
      color: IS_SERVER ? '{print dynamicValue[3]}' : colors[3],
      textTransform: 'uppercase',
      width: `${width}px`,
      height: `${height}px`,
      boxSizing: 'border-box',
      borderRadius: '50%',
      fontSize: '0',
      textAlign: 'center',
      ...style,
    },
    firstChild: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%'
    },
    lastChild: {
      fontSize: `${fontSize}px`,
      fontWeight: '500',
      display: 'inline-block',
      verticalAlign: 'middle',
    }
  };

  if (IS_SERVER) {
    const getValue = (v) => /^\{print .+?\}$/.test(v) ? v.substring(7, v.length - 1) : "'" + v.replace(/(')/g, '\\$1') + "'";
    const getStyle = (s) => Object.keys(styles[s]).map((p) => p.split(/(?=[A-Z])/).map((v) => v.toLowerCase()).join('-') + ':' + styles[s][p]).join(';');

    return `
      {literal}
        <div class="avatar${className ? ' ' + className : ''}">
          {if (${getValue(src)}) :}
            <img style="${getStyle('image')}" src="${src}" width="${width}" height="${height}" alt="{print ${getValue(firstName)}.replace(/"/g, '&amp;quot;')} {print ${getValue(lastName)}.replace(/"/g, '&amp;quot;')}"/>
          {else :}
            {exec}
              if (!('avatarColorVariants' in staticData)) staticData.avatarColorVariants = ${Helper.objectToString(colorVariants)};
              dynamicValue = 0;
              if (${getValue(firstName)}) dynamicValue += ${getValue(firstName)}.charCodeAt(0);
              if (${getValue(lastName)}) dynamicValue += ${getValue(lastName)}.charCodeAt(0);

              dynamicValue = staticData.avatarColorVariants[dynamicValue % staticData.avatarColorVariants.length];
            {/exec}
            <div style="${getStyle('parent')}">
              <div style="${getStyle('firstChild')}"></div>
              <div style="${getStyle('lastChild')}">{print ${getValue(firstName)} ? ${getValue(firstName)}.substring(0, 1) : ''}{print ${getValue(lastName)} ? ${getValue(lastName)}.substring(0, 1) : ''}</div>
            </div>
          {endif}
        </div>
      {/literal}
    `.replace(/\s{2,}/g, '');
  } else return (
    <div className={`avatar${className ? ' ' + className : ''}`} {...rest}>
      {
        src ?
        <img style={styles.image} src={src} width={width} height={height} alt={`${firstName} ${lastName}`}/> :
        <div style={styles.parent}>
          <div style={styles.firstChild}></div>
          <div style={styles.lastChild}>{firstName ? firstName.substring(0, 1) : ''}{lastName ? lastName.substring(0, 1) : ''}</div>
        </div>
      }
    </div>
  );
}

export default Avatar;