import React from 'react';
import ScrollHidden from './ScrollHidden';
import { useSelector } from 'react-redux';

export default ({ tabs, tab, setTab, tabsClassName }) => {
  const initTab = tab !== undefined ? tab : (tabs.constructor === Array ? tabs[0].key : false);
  const { lang, languages } = useSelector(({ language }) => language);
  const language = languages.filter((l) => l._id === lang)[0];

  const handleSetTab = ({ ev, key }) => {
    ev.preventDefault();

    if (document.activeElement) document.activeElement.blur();

    let targetA = ev.target;
    while (targetA.tagName.toLowerCase() !== 'a') targetA = targetA.parentNode;

    if (!targetA.className || !/(?:^| )current(?: |$)/.test(targetA.className)) {

      let tab = targetA;
      while (tab.tagName.toLowerCase() !== 'li') tab = tab.parentNode;

      let tabs = tab.parentNode.getElementsByTagName('li');
      let currentTab;
      for (let t = 0; t < tabs.length; t++) {
        if (/(?:^| )current(?: |$)/.test(tabs[t].getElementsByTagName('a')[0].className)) {
          currentTab = tabs[t];
          break;
        }
      }
      if (!currentTab) currentTab = tabs[0];

      const tabA = tab.getElementsByTagName('a')[0];
      const currentTabA = currentTab.getElementsByTagName('a')[0];
      const tabSpan = tab.getElementsByTagName('span')[0];
      const currentTabOffset = language.direction === 'ltr' ? currentTab.offsetLeft : currentTab.offsetParent.offsetWidth - currentTab.offsetLeft - currentTab.offsetWidth;
      const tabOffset = language.direction === 'ltr' ? tab.offsetLeft : tab.offsetParent.offsetWidth - tab.offsetLeft - tab.offsetWidth;
      const direction = language.direction === 'ltr' ? 'left' : 'right';

      tabA.className = 'current';
      currentTabA.removeAttribute('class');

      tabSpan.style[direction] = currentTabOffset - tabOffset + 'px';
      tabSpan.style.width = currentTab.offsetWidth + 'px';
      tabSpan.style.width = tabSpan.offsetWidth + 'px';

      tabSpan.className = 'transition';

      tabSpan.style.width = tab.offsetWidth + 'px';
      tabSpan.style[direction] = '0px';

      setTimeout(() => {
        tabSpan.removeAttribute('class');
        tabSpan.removeAttribute('style');
      }, 300);

      setTab(key);
    }
  };

  let checkPrint;

  return <ScrollHidden tag="ul" arrowsSelector="> li" props={{ className: `page-tabs${tabsClassName ? ' ' + tabsClassName : ''}` }}>
    {
      typeof tabs == 'string' ?
      `
        {literal}
          {for (let tabs = ${tabs}, tab = ${tab ? (/^\{print /.test(tab) ? tab.replace(/^\{print |\}/g, '') : "'" + tab + "'") : 'tabs[0].key'}, t = 0; t < tabs.length; t++) :}
            <li>
              <a{if (tabs[t].key == tab) :} class="current"{endif} href="#">
                <div class="inline">{print tabs[t].name}</div>
                <span></span>
              </a>
            </li>
          {endfor}
        {/literal}
      ` :
      tabs.map((t) => <li>
        {checkPrint = false}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a {...(t.key === initTab || (checkPrint = /^\{print /.test(initTab)) ? { className: checkPrint ? `{if ('${t.key}' == ${initTab.replace(/^\{print |\}/g, '')}) :}current{endif}` : 'current' } : {})} onClick={(ev) => handleSetTab({ ev, key: t.key })} href="#">
          <div className="inline">{t.name}</div>
          <span></span>
        </a>
      </li>)
    }
  </ScrollHidden>;
}