export const MAIN_MENU_LINKS = [
  {
    title: 'Home',
    link: '/'
  },
  {
    title: 'Europe',
    link: '/europe',
    props: { className: 'world-part' }
  },
  {
    title: 'America',
    link: '/america',
    props: { className: 'world-part' }
  },
  {
    title: 'CIS',
    link: '/cis',
    props: { className: 'world-part' }
  },
  {
    title: 'Asia',
    link: '/asia',
    props: { className: 'world-part' }
  },
  {
    title: 'Africa',
    link: '/africa',
    props: { className: 'world-part' }
  },
  {
    title: 'Australia',
    link: '/australia_newzealand',
    props: { className: 'world-part' }
  }
];