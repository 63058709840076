import firebaseSW from './firebaseSW';

let googleCaptchaSiteKeyV3;
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV3) {
  googleCaptchaSiteKeyV3 = window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV3;
  delete window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV3;
}

let googleCaptchaSiteKeyV2;
if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV2) {
  googleCaptchaSiteKeyV2 = window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV2;
  delete window.__REACT_INIT_DATA__.googleCaptchaSiteKeyV2;
}

export default {
	getCaptchToken() {
		return new Promise((resolve, reject) => {
			if (googleCaptchaSiteKeyV3 && 'grecaptcha' in window) {
				window.grecaptcha.ready(function() {
	        window.grecaptcha.execute(googleCaptchaSiteKeyV3, { action: 'submit' }).then(resolve);
	      });
			} else {
				reject(new Error('Captcha is not found'));
			}
		});
	},

	createCaptchaV2({ element, callback, expiredCallback, errorCallback, setClassToElement = true }) {
		if (googleCaptchaSiteKeyV2 && 'grecaptcha' in window) {
	    const className = element.getAttribute('class');

	    if (setClassToElement && !/(?:^| )visible(?: |$)/.test(className)) element.setAttribute('class', `${className} visible`);

	    window.grecaptcha.ready(() => {
	      window.grecaptcha.render(element, {
	        sitekey: googleCaptchaSiteKeyV2,
	        callback,
	        'expired-callback': expiredCallback,
	        'error-callback': errorCallback,
	      });
	    });
	  }
	},

	reloadCaptchaV2() {
		if ('grecaptcha' in window) window.grecaptcha.reset();
	},

	executeClientSystemTasks(user) {
		if (user?.clientSystemTasks?.length) {
			for (const task of user.clientSystemTasks) {
				switch (task.type) {
					case 'weatherWarningsSubscriptionUpdate': firebaseSW.postMessage(task).catch(() => {}); break;
				}
			}
		}
	}
};