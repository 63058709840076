import $ from 'jquery';

export default ({ event, setKeys }) => {
  event.persist();

  let params;
  let scrollElement;

  const drag = (ev) => {
    if (ev.clientX) params.dx = ev.clientX;
    if (ev.clientY) params.dy = ev.clientY;

    const left = params.dx - params.initX + params.dragElementPos[0];
    const top = params.dy - params.initY + params.dragElementPos[1];
    let px;
    let py;
    let x;
    let y;
  
    $(params.dragElement).css({left: left + 'px', top: top + 'px'});

    for (let i = 0; i < params.elements.length; i++) {
      if (!$(params.elements[i][0]).hasClass('drag-sortable-element')) continue;

      if (params.dragElementParams[0] > params.elements[i][1] || (params.dragElementParams[0] === params.elements[i][1] && left >= params.elements[i][1] && left <= params.elements[i][1] + params.elements[i][3] && left + params.dragElementParams[2] >= params.elements[i][1] + params.elements[i][3])) px = (params.elements[i][1] + params.elements[i][3] - left) / params.elements[i][3] * 100;
      else if (params.dragElementParams[0] < params.elements[i][1] || (params.dragElementParams[0] === params.elements[i][1] && left + params.dragElementParams[2] >= params.elements[i][1] && left + params.dragElementParams[2] <= params.elements[i][1] + params.elements[i][3] && left <= params.elements[i][1])) px = (left + params.dragElementParams[2] - params.elements[i][1]) / params.elements[i][3] * 100;
      else px = 0;
  
      if (params.dragElementParams[1] > params.elements[i][2] || (params.dragElementParams[1] === params.elements[i][2] && top >= params.elements[i][2] && top <= params.elements[i][2] + params.elements[i][4] && top + params.dragElementParams[3] >= params.elements[i][2] + params.elements[i][4])) py = (params.elements[i][2] + params.elements[i][4] - top) / params.elements[i][4] * 100;
      else if (params.dragElementParams[1] < params.elements[i][2] || (params.dragElementParams[1] === params.elements[i][2] && top + params.dragElementParams[3] >= params.elements[i][2] && top + params.dragElementParams[3] <= params.elements[i][2] + params.elements[i][4] && top <= params.elements[i][2])) py = (top + params.dragElementParams[3] - params.elements[i][2]) / params.elements[i][4] * 100;
      else py = 0;
  
      if (params.elements[i][1] <= left && params.elements[i][1] + params.elements[i][3] >= left + params.dragElementParams[2]) x = 1;
      else if (params.elements[i][1] >= left && params.elements[i][1] + params.elements[i][3] <= left + params.dragElementParams[2]) x = 2;
      else x = 0;
  
      if (params.elements[i][2] <= top && params.elements[i][2] + params.elements[i][4] >= top + params.dragElementParams[3]) y = 1;
      else if (params.elements[i][2] >= top && params.elements[i][2] + params.elements[i][4] <= top + params.dragElementParams[3]) y = 2;
      else y = 0;
  
      if ((px >= 55 && px <= 100 && ((py >= 55 && py <= 100) || y)) ||
          (py >= 55 && py <= 100 && ((px >= 55 && px <= 100) || x))) {
        const itemPlace = params.parent.find('> .drag-sortable-element-place');
        const cloneItemPlace = itemPlace[0].cloneNode(true);
        const scrollPosition = scrollElement.scrollTop();

        $(cloneItemPlace).insertAfter(params.elements[i][0]);
        itemPlace.replaceWith(params.elements[i][0]);

        scrollElement.scrollTop(scrollPosition);
  
        params.dragElementParams[0] = cloneItemPlace.offsetLeft;
        params.dragElementParams[1] = cloneItemPlace.offsetTop;
        params.elements = [];
        // eslint-disable-next-line no-loop-func
        params.parent.find('> *:not(.drag-sortable-element-place)').each(function () {
          if (this !== params.dragElement[0]) {
            params.elements.push([this, this.offsetLeft, this.offsetTop, $(this).width(), $(this).height()]);
          }
        });
        break;
      }
    }
    return false;
  };

  const scroll = (ev) => {
    const y = scrollElement[0] === window ? (window.pageYOffset || document.documentElement.scrollTop) : scrollElement[0].scrollTop;
    params.dragElementPos[1] += y - params.scrollY;
    params.scrollY = y;
    drag(ev);
  };

  const mouseUp = (ev) => {
    if (ev.which === 1 && params.dragElement) {
      params.dragElement.removeClass('drag');
      params.parent.find('> .drag-sortable-element-place').replaceWith(params.dragElement);
      $(window).off('mousemove', drag);
      scrollElement.off('scroll', scroll);
      $(window).off('mouseup', mouseUp);

      const keys = [];
      let collection = params.dragElement.attr('data-collection');

      (collection ? $(`[data-collection="${collection}"]`) : params.parent.find('> .drag-sortable-element')).each(function () {
        keys.push($(this).attr('data-key'));
      });

      params.parent.html('');
      params.defaultElements.map((e) => params.parent.append(e));

      if (params.defaultStyle) params.dragElement.attr('style', params.defaultStyle);
      else params.dragElement.removeAttr('style');

      setKeys(keys);
    }
  };

  if (event.nativeEvent.which === 1) {
    params = { defaultElements: [], dragElementParams: [], dragElementPos: [], elements: [], dragElement: $(event.target).closest('.drag-sortable-element') };
    params.parent = params.dragElement.parent();
    params.defaultStyle = params.dragElement.attr('style');

    const tagName = params.dragElement[0].tagName.toLowerCase();
    const width = params.dragElement.width() + parseFloat(params.dragElement.css('padding-left')) + parseFloat(params.dragElement.css('padding-right')) + parseFloat(params.dragElement.css('border-left-width')) + parseFloat(params.dragElement.css('border-right-width'));
    const height = params.dragElement.height() + parseFloat(params.dragElement.css('padding-top')) + parseFloat(params.dragElement.css('padding-bottom'))+ parseFloat(params.dragElement.css('border-top-width')) + parseFloat(params.dragElement.css('border-bottom-width'));

    try {
      let parent = params.parent;
      while (parent.length && parent[0] != document) {
        if (['scroll', 'auto'].includes(parent.css('overflow'))) throw parent;
        parent = parent.parent();
      }
      scrollElement = $(window);
    } catch (ex) {
      scrollElement = ex;
    }

    params.scrollY = scrollElement[0] === window ? (window.pageYOffset || document.documentElement.scrollTop) : scrollElement[0].scrollTop;
    params.initX = params.dx = event.clientX;
    params.initY = params.dy = event.clientY;
    
    params.parent.find('> *').each(function (n) {
      params.defaultElements.push(this);

      if (this !== params.dragElement[0]) {
        params.elements.push([this, this.offsetLeft, this.offsetTop, $(this).width(), $(this).height()]);
      }
      else params.dragElementNum = n;
    });

    params.dragElement.css({
      width: params.dragElement.width() + 'px',
      height: params.dragElement.height() + 'px',
      position: 'absolute',
      left: params.dragElementPos[0] = params.dragElementParams[0] = params.dragElement[0].offsetLeft,
      top: params.dragElementPos[1] = params.dragElementParams[1] = params.dragElement[0].offsetTop,
    }).addClass('drag');

    if (params.parent.find('> .drag-sortable-element-place').length) {
      params.parent.find('> .drag-sortable-element-place').css({width: (params.dragElementParams[2] = width) + 'px', height: (params.dragElementParams[3] = height) + 'px'});
    }
    else $('<' + tagName + (params.parent.attr('data-drag-attributes') ? ' ' + params.parent.attr('data-drag-attributes') : '') + ' class="drag-sortable-element-place' + (params.parent.attr('data-drag-classes') ? ' ' + params.parent.attr('data-drag-classes') : '') + '" style="width:' + (params.dragElementParams[2] = width) + 'px;height:' + (params.dragElementParams[3] = height) + 'px;border: 1px solid #7b7676; list-style: none; box-sizing: border-box;">' + (params.parent.attr('data-drag-place-html') ? params.parent.attr('data-drag-place-html') : '') + '</' + tagName + '>').insertBefore(params.dragElement);

    params.dragElement.insertAfter(params.parent.find(' > *:last-child'));

    $(window).on('mousemove', drag);
    scrollElement.on('scroll', scroll);
    $(window).on('mouseup', mouseUp);
  }

  return false;
};
