import React from 'react';
import Helper from 'src/front/helpers/Helper';
import useStyles from 'src/hooks/useStyles';
import { IS_SERVER, WEATHER_WARNINGS } from 'src/constants';

import styles from './style.css';

const serverCode = (warning) => `{exec}
	dynamicValue = {
		dateFrom: new Date(${warning}.dateFrom),
		dateUntil: new Date(${warning}.dateUntil),
		warningDetails: ${Helper.objectToString(WEATHER_WARNINGS)}.filter((w) => ${warning}.code in w.codes)[0]
	};
{/exec}`;

function WeatherWarning({ warning, lang, columns = null }) {
	useStyles(styles);

	const dateFrom = !IS_SERVER && (warning.dateFrom instanceof Date ? warning.dateFrom : new Date(warning.dateFrom));
	const dateUntil = !IS_SERVER && (warning.dateUntil instanceof Date ? warning.dateUntil : new Date(warning.dateUntil));
	const warningDetails = !IS_SERVER && WEATHER_WARNINGS.filter((w) => warning.code in w.codes)[0];

	return (
		<>
			{IS_SERVER && serverCode(warning)}
			<li className={`weather-warning seriousness-${IS_SERVER ? `{print dynamicValue.warningDetails.codes[${warning}.code]}` : warningDetails.codes[warning.code]}`}>
				<div className="icon">
					<img
						src={
							IS_SERVER ?
							`{print Helper.getFileUrl('weather-warning', dynamicValue.warningDetails.name.toLowerCase().replace(/\\s+/g, '-').replace(/[^a-z-]/ig, '') + '.svg')}` :
							Helper.getFileUrl('weather-warning', `${warningDetails.name.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z-]/ig, '')}.svg`)
						}
						alt={lang.t(IS_SERVER ? `{print dynamicValue.warningDetails.name}` : warningDetails.name, null, true)}
						title={lang.t(IS_SERVER ? `{print dynamicValue.warningDetails.name}` : warningDetails.name, null, true)}
					/>
				</div>
				<div className="info">
					<div className="name-date">
						<span className="name text-22 color-1">
							{
								IS_SERVER || lang.loaded ?
								lang.t(IS_SERVER ? '{print dynamicValue.warningDetails.name}' : warningDetails.name) :
								<span className="content-preloader va-middle" style={{ width: '180px', height: '20.5px' }}></span>
							}:
						</span>
						<span className="date text-3 color-12">
							{
								IS_SERVER ?
								`
									{if (dynamicValue.dateFrom.getFullYear() !== dynamicValue.dateUntil.getFullYear()) :}
										{print Helper.date('d M, Y', dynamicValue.dateFrom, '${lang.component}') + ' - ' + Helper.date('d M, Y', dynamicValue.dateUntil, '${lang.component}')}
									{elseif (dynamicValue.dateFrom.getMonth() !== dynamicValue.dateUntil.getMonth()) :}
										{print Helper.date('d M', dynamicValue.dateFrom, '${lang.component}') + ' - ' + Helper.date('d M, Y', dynamicValue.dateUntil, '${lang.component}')}
									{elseif (dynamicValue.dateFrom.getDate() !== dynamicValue.dateUntil.getDate()) :}
										{print Helper.date('d', dynamicValue.dateFrom) + ' - ' + Helper.date('d M, Y', dynamicValue.dateUntil, '${lang.component}')}
									{else :}
										{print Helper.date('d M, Y', dynamicValue.dateUntil, '${lang.component}')}
									{endif}
								` :
								(
									dateFrom.getFullYear() !== dateUntil.getFullYear() ?
									`${Helper.date('d M, Y', dateFrom, lang)} - ${Helper.date('d M, Y', dateUntil, lang)}` :
									(
										dateFrom.getMonth() !== dateUntil.getMonth() ?
										`${Helper.date('d M', dateFrom, lang)} - ${Helper.date('d M, Y', dateUntil, lang)}` :
										(
											dateFrom.getDate() !== dateUntil.getDate() ?
											`${Helper.date('d', dateFrom)} - ${Helper.date('d M, Y', dateUntil, lang)}` :
											Helper.date('d M, Y', dateUntil, lang)
										)
									)
								)
							}
						</span>
					</div>
					<div className="description text-29 color-1">{IS_SERVER ? `{print ${warning}.description}` : warning.description}</div>
				</div>
				{columns}
			</li>
		</>
	);
}

export default WeatherWarning;